import React from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import { Link, NavLink } from 'react-router-dom'
import { MdOutlineCancel } from 'react-icons/md'
import { SiShopware } from 'react-icons/si'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { links } from '../data/dummy'

const Sidebar = () => {
    const { currentColor, setActiveMenu, activeMenu, screenSize } = useStateContext();

    const handleCloseSidebar = () => {
        if (activeMenu !== undefined && screenSize <= 900) {
            setActiveMenu(false);
        }
    };

    const activeLink = 'flex items-center gap-3 pl-5 pr-3 pt-3 pb-2.5 rounded-lg bg-white text-md m-2 ml-3 mr-3 font-bold no-underline';
    const normalLink = 'flex items-center gap-3 pl-4 pr-3 pt-3 pb-2.5 rounded-lg text-md text-white dark:text-white hover:text-black hover:bg-white m-2 ml-3 mr-3 font-bold no-underline transition-colors duration-200';
    const customLink = 'customeLink flex items-center gap-3 pl-4 pr-3 pt-3 pb-2.5 rounded-lg text-md font-bold no-underline m-2 ml-3 mr-3 hover:bg-white';

    return (
        <div className="h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10" style={{ backgroundColor: 'rgba(30,58,138)' }}>
            {activeMenu && (
                <>
                    <div className="flex justify-between items-center">
                        <div className="flex justify-center mb-3 mt-4 bg-white w-48 rounded items-center mx-auto">
                            <img src="/logo.png" alt="AZZ Medical Associates" className="w-40" />
                        </div>
                    </div>
                    <div className="mt-10">
                        {links.map((item) => (
                            <div key={item.title}>
                                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                                    {item.title}
                                </p>
                                {item.links.map((link) => (
                                    <NavLink
                                        to={`/${link.name}`}
                                        key={link.name}
                                        onClick={handleCloseSidebar}
                                        style={({ isActive }) =>
                                            isActive
                                                ? { color: 'rgb(30, 50, 143)' }
                                                : { color: 'black' }
                                        }
                                        className={({ isActive }) => (isActive ? activeLink : customLink)}
                                    >
                                        <span className='ml-4'>🙍🏻‍♂️</span>
                                        <span className="capitalize" style={{ fontSize: '95%', fontFamily: 'sans-serif' }}>
                                            {link.name}
                                        </span>
                                    </NavLink>
                                ))}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Sidebar;





