
import React, { useState, useEffect, useRef } from "react";
import { getRequest, postRequest, getRequestWithToken } from "../../api/api";
import { FaCheck } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Select from 'react-select';
import { showToast } from '../../services/toastifyservices';
//import { ToastContainer, toast } from 'react-toastify';

import { formatText } from "../../helper/index";
import CommonSpinner from '../../services/commonSpinner'

import './assistant.css';

const Assistant = () => {
    const [providers, setProviders] = useState([]);
    const [dictations, setDictations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [provider_id, setProviderId] = useState("");
    const [status, setStatus] = useState("");
    const [date, setDate] = useState("");
    const [AssistantName, setAssistantName] = useState("");

    const [transcriptionSegments, setTranscriptionSegments] = useState([]);

    useEffect(() => {
        const fetchProviders = async () => {
            const user = JSON.parse(localStorage.getItem("user"));
            setAssistantName(user.name)
            console.log("user", user)
            try {
                const { data } = await getRequestWithToken("get-providers");
                setProviders(data.providers || []);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProviders();
    }, []);
    //--------------------------on Search Button---------------------//

    const handleSearch = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setLoading(true);
        const Payload = {
            provider_id: provider_id,
            status: status,
            date: date,
        };
        try {
            const response = await getRequest("get-provider-dictations", Payload);
            setLoading(false);
            const result = response.data;
            setDictations([]);
            if (result.status) {
                if (result.dictations.length > 0) {
                    showToast('success', result.message);
                    const formattedDictations = result.dictations.map((dictation) => {
                        // Parse transcription JSON string into an array of segments
                        let transcriptionSegments = [];
                        try {
                            transcriptionSegments = dictation.transcription ? JSON.parse(dictation.transcription) : [];
                        } catch (error) {
                            console.error("Failed to parse transcription:", error);
                        }

                        return {
                            ...dictation,
                            soap_note: dictation.soap_note ? formatText(dictation.soap_note) : dictation.soap_note,
                            comments: dictation.comments ? formatText(dictation.comments) : dictation.comments, // Format the comments
                            transcriptionSegments, // Add parsed segments to the dictation
                        };
                    });

                    setDictations(formattedDictations);
                } else {
                    showToast('error', "No Record Found");
                }
            } else {
                showToast('error', result.message);
            }
        } catch (error) {
            console.error("Error:", error);
            setLoading(false);
        }
    };

    //-------------------Mark As Done Function-------------------//
    const markAsDone = async (id) => {
        debugger
        try {
            setLoading(true);
            const payload = {
                dictation_id: id,
            };
            const response = await postRequest("update-dictation-status", payload);
            const result = response.data;
            if (result.status) {
                showToast('success', result.message);
                handleSearch();
            }

        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    const audioRefs = useRef([]); // Array to hold refs for each audio player

    const [currentTime, setCurrentTime] = useState({}); // To store current time for each audio

    const playFromSegment = (start, dictationIndex) => {
        const audio = audioRefs.current[dictationIndex];
        if (audio) {
            audio.currentTime = start;
            audio.play();
        }
    };

    const handleTimeUpdate = (dictationIndex) => {
        const audio = audioRefs.current[dictationIndex];
        if (audio) {
            setCurrentTime((prev) => ({
                ...prev,
                [dictationIndex]: audio.currentTime.toFixed(1), // Store current time formatted
            }));
        }
    };



    return (
        <div className="mt-3 p-2 md:p-10 bg-gray-200">
            <div className="card">
                <span className="text-start font-bold ml-4 p-2 headingtext">{AssistantName}</span>
            </div>
            <div className="card mt-3">

                <form onSubmit={handleSearch}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <label
                                    htmlFor="providerselect"
                                    className="form-label font-bold"
                                    style={{ minWidth: "100px" }}
                                >
                                    Provider
                                </label>
                                <select
                                    className="form-select "
                                    aria-label="Default select example"
                                    id="providerselect"
                                    value={provider_id}
                                    onChange={(e) => setProviderId(e.target.value)}
                                    required
                                >
                                    <option value="">Select Name</option>
                                    {providers.map((provider) => (
                                        <option key={provider.azz_id} value={provider.azz_id}>
                                            {provider.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label
                                    htmlFor="datePicker"
                                    className="form-label font-bold"
                                    style={{ minWidth: "100px" }}
                                >
                                    Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="datePicker"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col-md-3">
                                <label
                                    htmlFor="statusSelect"
                                    className="form-label font-bold"
                                    style={{ minWidth: "100px" }}
                                >
                                    Status
                                </label>
                                <select
                                    className="form-select "
                                    aria-label="Default select example"
                                    id="statusSelect"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    required
                                >
                                    <option value=""> </option>
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                </select>
                            </div>
                            <div className="col-md-3 mt-4">

                                <button className="btn btn-primary mt-2  fw-bold btncolor" type="submit">Search</button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="mt-3">
                {dictations.length > 0 && (
                    dictations.map((dictation, index) => (
                        <div key={index} className="card mb-3">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <p className="text-uppercase mb-0">
                                        <b className="patientNameText">{dictation.patient_name}</b> 
                                    </p>
                                    {dictation.status === "pending" && (
                                        <button
                                            className="btn btn-primary mt-2 fw-bold btncolor d-inline-flex align-items-center"
                                            onClick={() => markAsDone(dictation.id)}
                                        >
                                            <FaCheck className="me-2" />
                                            <span>Mark As Done</span>
                                        </button>
                                    )}
                                </div>


                            </div>

                            {/* Accordion for Soap Note */}
                            <div className="row">
                                <div className="col-12">
                                    <Accordion defaultActiveKey="0" alwaysOpen className="custom-accordion">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>Soap Note</b></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="row">
                                                    {/* Left Column: Soap Note */}
                                                    <div className="col-md-6 soap-note-column">
                                                        <div className="content">
                                                            <div dangerouslySetInnerHTML={{ __html: dictation.soap_note }} />
                                                        </div>
                                                        {dictation.comments && dictation.comments.trim() !== "" && (
                                                            <div className="comments-div">
                                                                <b>Comments:</b>
                                                                <div dangerouslySetInnerHTML={{ __html: dictation.comments }} />
                                                                </div>
                                                        )}
                                                    </div>
                                                    {/* Right Column: Transcription */}
                                                    <div className="col-md-6 transcription-column">
                                                        <div className="transcription-content">
                                                            {dictation.transcriptionSegments.length > 0 ? (
                                                                <>
                                                                    <p className="fw-bold">Transcription</p>
                                                                    {dictation.transcriptionSegments.map((segment, segmentIndex) => (
                                                                        <p
                                                                            key={segmentIndex}
                                                                            onClick={() => playFromSegment(segment.start, index)}
                                                                            style={{ cursor: 'pointer' }}
                                                                        >
                                                                            {segment.text}
                                                                        </p>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <p>No transcription available.</p>
                                                            )}
                                                        </div>

                                                        {/* Audio Player for each dictation */}
                                                        <div className="audio-player-container">
                                                            <audio
                                                                controls
                                                                ref={(el) => (audioRefs.current[index] = el)} // Store each audio ref
                                                                src={dictation.audio_file || ''}
                                                                className="audio-player"
                                                            >
                                                                Your browser does not support the audio element.
                                                            </audio>

                                                            {/* Display the current time of the audio player */}
                                                            {/* <div className="timer">
                                                                {currentTime[index] ? `${currentTime[index]}s` : '0.0s'}
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            {loading && <CommonSpinner />}
            
           
        </div>
    );
};

export default Assistant;
