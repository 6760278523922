import React, { useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';
import { Cart, Chat, Notification, UserProfile } from '.';
import { postRequest, getRequestWithToken } from "../api/api";
import { showToast } from '../services/toastifyservices';
import CommonSpinner from '../services/commonSpinner'

const user = JSON.parse(localStorage.getItem('user'));

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (

  <TooltipComponent content={title} position="BottomCenter" cssClass="my-tooltip">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color: 'black', marginLeft: '-26px' }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      {dotColor && (
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex rounded-full h-2 w-2 top-2 right-2"
        />
      )}
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const navigate = useNavigate();
  const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();
  const [showLogout, setShowLogout] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');

  const handleArrowClick = () => {
    setShowLogout(prevShowLogout => !prevShowLogout);
  };

  const handleLogoutClick =async () => {
    setLoading(true);
   
    const Payload = {
      token: token,
    };


    try {
      const response = await getRequestWithToken("logout");
      setLoading(false);
      const result = response.data;
      debugger
      if (result.status) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
      } else {
        showToast("error", result.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setShowLogout(false);
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="flex justify-between p-2 md:mr-6 md:ml-6 relative">
      <NavButton customFunc={handleActiveMenu} title="Menu" icon={<AiOutlineMenu />} color={currentColor} />

      <div className="flex items-center ml-auto">
        <div className="flex items-center ml-4 relative">
          <TooltipComponent position="BottomCenter">
            <div
              className="flex items-center gap-2 cursor-pointer px-2 py-1 hover:bg-light-gray rounded-lg"
              onClick={handleArrowClick}
            >
              <img className="rounded-full w-8 h-8" src={avatar} alt="user-profile" />
              <p className="text-gray-400 text-14">
                {/* Optional: Display username */}
                {/* <span>Hi,</span> <span className="ml-1 font-bold">{user?.name}</span> */}
              </p>
              <MdKeyboardArrowDown className="text-gray-400 text-14" />
            </div>
          </TooltipComponent>
          {showLogout && (
            <button
              onClick={handleLogoutClick}
              style={{
                position: 'absolute',
                top: '40px',  // Adjusted to place button below arrow
                left: '0',
                backgroundColor: 'rgb(20, 40, 120)',
                padding: '5px 10px',
                borderRadius: '5px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                border: 'none',
                cursor: 'pointer',
                color: 'white'
              }}
            >
              Logout
            </button>
          )}
        </div>

        {isClicked.notification && <Notification />}
        {isClicked.chat && <Chat />}
        {isClicked.cart && <Cart />}
        {isClicked.userProfile && <UserProfile />}
      </div>
      {loading && <CommonSpinner />}

    </div>
  );
};

export default Navbar;
