import React from 'react';
import { ScaleLoader } from "react-spinners"; // Import ScaleLoader here
import './commonspinner.css';

const CommonSpinner = () => {
  return (
    <div className="spinner-overlay flex flex-col items-center justify-center" style={{ height: '100vh' }}>
      <ScaleLoader
        color={"#808080"} // Use the 'color' prop instead of 'colors'
        height={50}
        width={4}
        radius={2}
        margin={2}
        loading={true} // Change 'loading="true"' to loading={true}
      />
    </div>
  );
};

export default CommonSpinner;
